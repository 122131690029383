import Tabby from 'tabbyjs';
(() => {

  const initTabs = () => {
    const tabs = new Tabby('[data-tabs]');
  }

  const init = () => {
    initTabs();
  }

  return init();
})()
